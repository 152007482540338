import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Modal, DynamicGrid, DeviceCard } from '@soluto-private/mx-asurion-ui-react';
import { publishAnonEvent } from '../../services/reportingService';
import { generateDeviceID } from '../../services/clientInfoService';
import AsurionLogo from '../assets/asurion-logo.svg';
import TelusLogo from '../assets/telus-logo-standalone.svg';
import UsCelluarLogo from '../assets/uscellular-logo-standalone.svg';
import LibertyLogo from '../assets/liberty-logo-standalone.svg';
import CoxMobileLogo from '../assets/cox-logo-standalone.svg';

const screen = 'MarketingLandingPage';
const partners = [
  {
    name: 'Asurion',
    logo: AsurionLogo,
    mxGalleryUrlParams: 'mxclient=asurion&mxproducttype=default',
  },
  {
    name: 'Telus',
    logo: TelusLogo,
    mxGalleryUrlParams: 'mxclient=telus',
  },
  {
    name: 'UsCelluar',
    logo: UsCelluarLogo,
    mxGalleryUrlParams: 'mxclient=uscellular',
  },
  {
    name: 'Liberty',
    logo: LibertyLogo,
    mxGalleryUrlParams: 'mxclient=liberty',
  },
  {
    name: 'Cox',
    logo: CoxMobileLogo,
    mxGalleryUrlParams: 'mxclient=cox',
  },
];

const PartnerPicker = ({ handlePartnerPickerCloseClick }) => {
  const classes = useStyles();
  const handlePartnerSelection = (mxClientUrl, name) => {
    handlePartnerPickerCloseClick();
    const event = {
      eventType: 'LinkClicked',
      link: name,
      screen: 'PartnerPicker',
    };
    publishAnonEvent(event);
    const uniqueId =
      localStorage && localStorage.getItem('uniqueId')
        ? localStorage.getItem('uniqueId')
        : generateDeviceID();
    let path = `${process.env.REACT_APP_REDIRECT_URL}?${mxClientUrl}&u=${uniqueId}`;
    window.location.assign(path);
  };

  useEffect(() => {
    publishAnonEvent({
      eventType: 'DialogShown',
      dialog: 'PartnerPicker',
      screen,
    });
  }, []);
  return (
    <Modal
      title={<div className={classes.title}>Sign in to your photo gallery</div>}
      isOpen={true}
      titleFontSize={5}
      subTitle={
        <div className={classes.subTitle}>
          Choose the carrier tied to your protection plan or service
        </div>
      }
      className={classes.modal}
      onCloseClick={() => handlePartnerPickerCloseClick()}
      dismissButtonClassName={classes.dismissButton} // need to remove styling for dismiss button when we migrate AUI modal to higher version i.e. > V4. This is because these styling is already handled in new version
    >
      <div>
        <p className={classes.dynamicGridPara}>
          <DynamicGrid data-test-id="PartnerGrid" gapSize={1} minWidth={8}>
            {partners.map(val => (
              <DeviceCard
                className={classes.deviceCard}
                size="lg"
                imageSrc={val.logo}
                onClick={() => {
                  handlePartnerSelection(val.mxGalleryUrlParams, val.name);
                }}
              />
            ))}
          </DynamicGrid>
        </p>
      </div>
    </Modal>
  );
};

const breakpoint = 'sm';
const breakpointXs = 'xs';
const useStyles = makeStyles(theme => ({
  modal: {
    width: '500px !important',
    [theme.breakpoints.down(breakpoint)]: {
      width: '310px !important',
    },
    '& header': {
      [theme.breakpoints.down(breakpoint)]: {
        marginTop: '0px',
      },
    },
  },
  title: {
    textAlign: 'left',
    fontFamily: 'ApercuWeb',
    // fontWeight: 400,
    fontSize: '1.5rem',
    marginTop: '10px',
    // [theme.breakpoints.down(breakpoint)]: {
    //   padding: '1.5rem 4.5rem 0px 1.5rem',
    // },
    [theme.breakpoints.down(breakpointXs)]: {
      width: '250px',
      // marginTop: '35px',
    },
  },

  subTitle: {
    textAlign: 'left',
    marginTop: '2px',
    fontFamily: 'ApercuWeb',
    // fontWeight: 400,
    fontSize: '0.875rem',
  },
  dynamicGridPara: {
    padding: '0px 40px',
    [theme.breakpoints.down(breakpoint)]: {
      padding: '0px',
    },
  },
  deviceCard: {
    hight: '109px',
    width: '168px',
    [theme.breakpoints.down(breakpoint)]: {
      width: '130px',
    },
    '& div': {
      justifyContent: 'center',
    },
  },
  dismissButton: {
    border: '0.0625rem solid #d5d6da !important',
    top: '0.5rem !important',
    right: '0.5rem !important',
  },
}));

export default PartnerPicker;
